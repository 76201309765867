import {ITopUser} from "./index";
import {FC} from "react";
import {Skeleton} from "../../../UI/Loaders/Skeleton";
import {Photo} from "../../../UI/Avatar";
import {API_URL} from "../../../../Api/config";
import {Tools} from "../../../../Tools";
import styles from '../../UsersList.module.scss'
import {LinkOutlined} from "@ant-design/icons";


type IRowBody = {
    loading: boolean,
    user: ITopUser | undefined,
    i: number
}

const getPosition = (a: number) => {
    switch (a) {
        case 1:
            return '🥇'
        case 2:
            return '🥈'
        case 3:
            return '🥉'
        default:
            return '#' + a.toString()
    }
}

export const TopBodyRow: FC<IRowBody> = ({loading,user,i}) => {


    if (!user || loading) return <Skeleton/>
    const username = (() => {
        if (user.username) {
            return user.username
        }
        if (user.first_name) {
            return user.first_name
        }
        return `id${user.tid.toString()}`
    })()

    return (
        <div className={styles.row} onClick={Tools.openProfile(user.username)}>
            <div className={styles.row_info}>
                <Photo src={`${API_URL}/users.photo/${user.tid}`} alt={username.slice(0,2)}/>

                <div>
                    <p className={styles.row_info_name}>
                        {user.username ?? `${username}`}
                        {user.username ? <LinkOutlined className={styles.row_info_link} /> : ''}
                    </p>
                    <p className={styles.row_info_balance}>
                        {Tools.Spacer(user.time)}
                    </p>
                </div>

            </div>

            <div>
                <p className={styles.row_info_position}>
                    {getPosition(i+1)}
                </p>
            </div>

        </div>
    )
}
