import {Drawer} from "antd";
import React, {useState} from "react";
import {getter, setter, useGlobalValue} from "elum-state/react";
import {ANCHOR, LANGUAGE_CODE, SIDEBARS} from "../../../../state/elum";
import styles from './DailyBonus.module.scss'
import {game} from "../../../../Api";
import {DailyBonusContent} from "./entity/Content";
import {daily} from "./data/texts";
import {CloseOutlined} from "@ant-design/icons";

export type IDailyBonus = {
    collecting_days: number
    can_collect: boolean
}

export const DailyBonus = () => {
    const [open, setOpen] = useState(getter(SIDEBARS) === 'daily_bonus');
    const [dailyBonus, setDailyBonus] = useState<IDailyBonus>({} as IDailyBonus)
    const langCode = useGlobalValue(LANGUAGE_CODE)


    const getDailyBonusInfo = async () => {
        try {
            const {data} = await game.bonus.get()
            setDailyBonus(data)

        } catch (e) {
            console.log(e)
        }
    }

    const collectAward = async () => {
        try {
            await game.bonus.collect()
            return getDailyBonusInfo()

        } catch (e) {
            console.log(e)
        }

    }


    const onClose = () => {
        setOpen(false);
        setTimeout(() =>
            setter(SIDEBARS, '')
        , 500)
    };

    const classNames = {
        mask: styles.sidebar_mask,
        content: styles.sidebar_content,
        wrapper: styles.sidebar_wrapper,
        body: styles.sidebar_body
    }


    React.useEffect(() => {
        getDailyBonusInfo()
    },[])

    if (dailyBonus.can_collect === undefined || dailyBonus.collecting_days === undefined) return <></>

    return (
        <Drawer
            classNames={classNames}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            open={open}
        >

            <div className={'cross'} onClick={onClose}>
                <CloseOutlined/>
            </div>

            <DailyBonusContent {...dailyBonus} langCode={langCode}/>
            <button className={`btn_primary ${styles.content_btn}`} onClick={collectAward}
                    disabled={!dailyBonus.can_collect}>
                {daily.button[dailyBonus.can_collect ? 'action' : 'not_action'][langCode]}
            </button>

        </Drawer>
    )
}
