import {Skeleton} from "../../../UI/Loaders/Skeleton";
import React, {FC} from "react";
import {ITask} from "./index";
import styles from './Body.module.scss'
import {setter} from "elum-state/react";
import {PAYLOAD, SIDEBARS} from "../../../../state/elum";

type ITasksBody = {
    tasks: ITask[] | undefined[]
}

export const TasksBody: FC<ITasksBody> = ({tasks}) => {

    const clickOnTask = (id: number | undefined, payload: string | undefined) => {
        if (!id) return 0

        setter(SIDEBARS, 'task')
        setter(PAYLOAD, {id, payload})

    }

    return (
        <>
            {tasks.map((el, i) =>
                <div className={styles.body_tasks__row} onClick={() => clickOnTask(el?.id, el?.payload)} key={`${i}_task`}>
                    {
                        el ?
                            <>
                                <img src={`./assets/tasks/${el.icon}`} className={styles['body_tasks__row-img']}/>
                                <div className={styles['body_tasks__row-info']}>
                                    <p className={styles['body_tasks__row-title']}>{el.title}</p>
                                    <p className={styles['body_tasks__row-award']}>+ {el.award} min.</p>
                                </div>
                            </>
                            : <Skeleton/>
                    }
                </div>
            )}
        </>
    )
}
