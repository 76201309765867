import styles from './Friends.module.scss'
import {FriendsContent} from "../../../Engine/Components/Screens/Friends";
import useScroll from "../../../Engine/Hooks/useScroll";
import clsx from "clsx";
import {Header} from "../../../Engine/Components/PreHeader";

export const Friends = () => {
    const scrollToEnd = useScroll('friends')
    return (
        <div id='friends' className={clsx([styles.friends, 'fade-in'])}>
            <Header.Friends/>
            <FriendsContent scrollToEnd={scrollToEnd}/>
        </div>
    )
}
