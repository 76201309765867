import styles from './Head.module.scss'
import {dropHeader} from "./texts";
import {setter, useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE, SIDEBARS} from "../../../../state/elum";

export const AirdropHead = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE)

    const openDaily = () => setter(SIDEBARS, 'daily_bonus')

    return (
        <div className={styles.header}>
            {/*<img src={'./assets/logo.png'}/>*/}
        </div>
    )
}
