import { SadDuck} from "../../../../Assets/icons";
import clsx from "clsx";
import styles from '../index.module.scss'
import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE} from "../../../state/elum";


const text = {
    en: "Oops, your time is up, and we warned you!",
    ru: "Упс, твоё время вышло, а мы предупреждали!"
}

export const TimeEnd = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE);
    return (
        <div className={clsx([styles.classic])}>
            <SadDuck/>
            <p className={styles.header_title}>{text[langCode]}</p>
        </div>
    )
}