import React, {FC} from "react";
import {MenuItem} from "./MenuItem";
import {IMenuItem} from "../MenuPanel";
import styles from './Menu.module.scss'
import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE, THEME, USER} from "../../../../state/elum";
import {MenuItemWrapper} from "./MenuItemWrapper";
import {Skeleton} from "../../Loaders/Skeleton";
import {APP_MODE} from "../../../../Api/config";
import {IS_TESTER} from "../../../../Api/config.search";


type IMenu = {
    onClick: (...a: any) => any
    selectedKey: string
    items: IMenuItem[]
}
export const Menu: FC<IMenu> = ({
                                    onClick,
                                    selectedKey,
                                    items
                                }) => {
    const theme = useGlobalValue(THEME)
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const {is_loaded} = useGlobalValue(USER)
    const DEV_ITEMS = IS_TESTER ? items.filter(el => el.menuKey !== 'drop') : items

    return (
        <div className={`${styles[theme]} ${styles.menu}`}>
            { is_loaded ?
                DEV_ITEMS.map((el, i) =>
                    <MenuItemWrapper
                        type={el.type}
                        animated={el.icon_type === 'animated'}
                        key={`${el.menuKey}_menu_element_${i}`}
                        selectedKey={selectedKey}
                        onClick={onClick}
                        menuKey={el.menuKey}
                        theme={theme}
                    >
                        <MenuItem
                            langCode={langCode}
                            icon={el.icon}
                            title={el.title}
                        />
                    </MenuItemWrapper>
                ) :
                <Skeleton/>
            }
        </div>
    )

}
