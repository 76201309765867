import styles from  './Game.module.scss'
import {TimeBlock} from "../../../Engine/Components/Screens/Game/TimeBlock";
import React from "react";
import clsx from "clsx";
import {useGlobalValue} from "elum-state/react";
import {BANNED} from "../../../Engine/state/elum";
export const Game = () => {
    const [height, setHeight] = React.useState(0);

    React.useEffect(() => {
        setHeight(Number(document.getElementById('menu')?.clientHeight));
    }, [])


    if (!height) return <></>;



    return (
        <div id='game-block' className={clsx([styles.game, 'fade-in'])}
             style={{paddingBottom: `${height + 10}px`}}
        >
            <TimeBlock/>
        </div>
    )
}
