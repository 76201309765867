import {MenuWrapper} from "../../../Engine/Components/UI/Menu/MenuWrapper";
import {Screens} from "../../Screens";
import {Sidebars} from "../../../Engine/Components/UI/Sidebars";
import {Modals} from "../../../Engine/Components/UI/Modals";
import React from "react";

export const Home = () => {
    return (
        <MenuWrapper>
            <Screens/>
            <Sidebars/>
            <Modals/>
        </MenuWrapper>
    )
}