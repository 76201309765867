import { useCallback, useEffect, useRef } from 'react';
import {game} from "../Api";
import {Tools} from "../Tools";
import {useEnqueueSnackbar} from "./useSnackbar";
import {generalCasino} from "../../Events/Casino/API";


export interface useAdsgramParams {
    blockId: string;
    onReward: () => void;
    onError?: (result: any) => void;
}

export function useAdsgram({ blockId, onReward, onError }: useAdsgramParams): () => Promise<void> {
    const AdControllerRef = useRef<any | undefined>(undefined);

    const prepare = async () => {
        try {
            const {data} = await game.ads.prepare()
            return data;

        } catch (e) {

        }
    }

    useEffect(() => {
        AdControllerRef.current = window.Adsgram?.init({ blockId, debug: false, debugBannerType: 'FullscreenMedia' });
    }, [blockId]);




    return useCallback(async () => {
        if (AdControllerRef.current) {
            const prepareAds = await prepare()

            if (!prepareAds.success) {
                return onError?.(prepareAds.error)
            } else {
                //return onReward();
            }

            AdControllerRef.current
                .show()
                .then(() => {
                    onReward();
                })
                .catch((result: any) => {
                    onError?.(result);
                });
        } else {
            onError?.({
                error: true,
                done: false,
                state: 'load',
                description: 'Adsgram script not loaded',
            });
        }
    }, [onError, onReward]);
}


export const useAdsgramCallback = (hashString: string, callback?: (...a: any) => any, event = false) => {
    const {openSnackbar}= useEnqueueSnackbar()

    const onReward = useCallback(async () => {

        const {hash} = Tools.getHash(hashString)


        const request = !event ? (hash: string) => game.ads.check(hash) : (hash: string) => generalCasino.ads.check()


        try {
            const {data} = await request(hash)


            if (data.success) {
                if (callback) callback()
                return openSnackbar({message: 'Success!', variant: 'success'})
            }
            return openSnackbar({message: data?.error?.error_message ?? 'Unknown error', variant: 'error'})

        } catch (e) {

            console.log(e)

        }

    }, []);
    const onError = useCallback((result: any) => {
        openSnackbar({message: result?.error_message ?? 'Unknown error', variant: 'error'})
    }, []);

    return {onReward, onError}
}