import React, {FC, SyntheticEvent, useRef} from "react";
import {Avatar} from "antd";
import styles from './Avatar.module.scss'
import {Tools} from "../../../Tools";
import clsx from "clsx";


type IPhoto = {
    src: string
    alt?: string
    w?: string,
    h?: string,
    className?: string
}

export const Photo: FC<IPhoto> = ({src, alt, w, h, className}) => {
    const [loaded, setLoaded] = React.useState(false)
    const ref = useRef<null | HTMLImageElement>(null)
    const backgroundColor = Tools.getRandomColor()
    const textColor = Tools.getContrastColor(backgroundColor)

    const handleImageLoad = (e: SyntheticEvent) => {

        if (ref.current) {
            //ref.current?.classList.add(styles.avatar_loaded)
        }
        setLoaded(true)
    };


    return (
        <div className={clsx([styles.avatar_wrapper, className])}>
            <div
                className={`${styles.avatar} ${styles[textColor]} ${loaded ? styles.avatar_wrapper_loaded : ''}`}
                style={{backgroundColor: backgroundColor + '7F', width: w, height: h}}>
                <p>{alt?.toUpperCase()}</p>
            </div>
            <img className={loaded ? styles.avatar_loaded : ''} ref={ref} src={src} onLoad={handleImageLoad}/>
        </div>
)
}
