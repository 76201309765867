


export const theming = {
    dark: {
        token: {

            colorPrimary: '#5db4f5',
            // borderRadius: 2,
            //
            //
            // colorBgContainer: '#f6ffed',
        },
    },
    light: {
        token: {

            colorPrimary: '#0079fd',
            // borderRadius: 2,
            //
            //
            colorBgContainer: '#f6ffed',
        },
    }
}

