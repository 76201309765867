import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './Home';

const Casino = lazy(() => import('../../Events/Casino'));

export const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route
                    path="/casino"
                    element={
                        <Suspense fallback={<div>Loading...</div>}>
                            <Casino />
                        </Suspense>
                    }
                />
            </Routes>
        </Router>
    );
};
