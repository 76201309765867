import styles from './Earn.module.scss'
import {EarnHead} from "../../../Engine/Components/Screens/Earn/Head";
import {EarnBody} from "../../../Engine/Components/Screens/Earn/Body";
import clsx from "clsx";
import {Header} from "../../../Engine/Components/PreHeader";

export const Earn = () => {
    const height = document.getElementById('menu')!.clientHeight

    return (
        <div className={clsx([styles.earn, 'fade-in'])} style={{paddingBottom: `${height + 10}px`}}>
            <Header.Earn/>
            <EarnHead/>
            <EarnBody/>
        </div>
    )
}
