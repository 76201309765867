import React from 'react';
import Lottie from 'lottie-react';
import animationData from './Diamond.json';

const LottieAnimation = ({w = '15px', h = '15px'}) => {
    return (
        <div style={{ width: w, height: h }}>
            <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
};

export default LottieAnimation;