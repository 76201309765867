import React, { useRef, useEffect } from 'react';

interface Dot {
    x: number;
    y: number;
    radius: number;
    alpha: number;
    dx: number;
    dy: number;
}

interface IProps {
    h?: number | string;
    w?: number | string;
    dotsCount?: number;
    dotsRadius?: number;
    className?: string;
}

const MovingDotsCanvas: React.FC<IProps> = ({
                                                h = '100%',
                                                w = '100%',
                                                dotsCount = 150,
                                                dotsRadius = 2,
                                                className
                                            }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        let dots: Dot[] = [];

        const generateDots = () => {
            dots = [];
            for (let i = 0; i < dotsCount; i++) {
                dots.push({
                    x: Math.random() * canvas.width,
                    y: Math.random() * canvas.height,
                    radius: Math.random() * dotsRadius,
                    alpha: Math.random(),
                    dx: (Math.random() - 0.5) * 2,
                    dy: (Math.random() - 0.5) * 2,
                });
            }
        };

        const draw = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            dots.forEach(dot => {
                ctx.beginPath();
                ctx.arc(dot.x, dot.y, dot.radius, 0, Math.PI * 2, false);
                ctx.fillStyle = `rgba(255, 255, 255, ${dot.alpha})`;
                ctx.fill();

                dot.x += dot.dx;
                dot.y += dot.dy;

                if (dot.x + dot.radius > canvas.width || dot.x - dot.radius < 0) {
                    dot.dx = -dot.dx;
                }
                if (dot.y + dot.radius > canvas.height || dot.y - dot.radius < 0) {
                    dot.dy = -dot.dy;
                }
            });

            requestAnimationFrame(draw);
        };

        const resizeCanvas = () => {
            const parent = canvas.parentElement;
            if (parent) {
                const { width, height } = parent.getBoundingClientRect();
                canvas.width = width;
                canvas.height = height;
            } else {
                canvas.width = typeof w === 'number' ? w : canvas.clientWidth;
                canvas.height = typeof h === 'number' ? h : canvas.clientHeight;
            }
            generateDots();
        };

        resizeCanvas();
        draw();

        window.addEventListener('resize', resizeCanvas);

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [dotsCount, dotsRadius, w, h]);

    return <canvas ref={canvasRef} style={{ display: 'block', width: w, height: h }} className={className} />;
};

export default MovingDotsCanvas;
