import {Divider} from "../../../UI/Divider";
import styles from './Body.module.scss'
import {game} from "../../../../Api";
import React from "react";
import {TasksBody} from "./Tasks";
import {getter, useGlobalValue} from "elum-state/react";
import {ANCHOR, LANGUAGE_CODE} from "../../../../state/elum";
import {body} from "./texts";

export type ITask = {
    "id": number,
    "title": string,
    "icon": string,
    description: string
    payload: string
    award: number,
    state: number | null
}

export const AirdropBody = () => {

    const [tasks, setTasks] = React.useState<ITask[] | undefined[]>([]);
    const langCode = getter(LANGUAGE_CODE)
    const anchor = useGlobalValue(ANCHOR)


    const getTasks = async () => {
        try {
            const {data} = await game.tasks.get('air_drop')
            setTasks(data)
        } catch (e) {
            console.log(e)
        }
    }


    React.useEffect(() => {
        getTasks()
    },[anchor])

    return (
        <div className={styles.body}>
            <Divider>{body.title[langCode]}</Divider>
            <div className={styles.body_tasks}>
                <TasksBody tasks={tasks}/>

            </div>
        </div>
    )
}
