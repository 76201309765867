import { Drawer} from "antd";
import React, {useCallback, useState} from "react";
import {getter, setter, useGlobalValue} from "elum-state/react";
import {ANCHOR, ISidebars, LANGUAGE_CODE, PAGE, Pages, PAYLOAD, SIDEBARS} from "../../../../state/elum";
import styles from './Task.module.scss'
import {game} from "../../../../Api";
import {ITask} from "../../../Screens/Earn/Body";
import {taskTexts} from "./texts";
import {useEnqueueSnackbar} from "../../../../Hooks/useSnackbar";
import {Telegram} from "../../../../../index";
import { useTonConnectModal} from "@tonconnect/ui-react";
import {CloseOutlined} from "@ant-design/icons";
import {Photo} from "../../Avatar";
import {useAdsgram, useAdsgramCallback} from "../../../../Hooks/useAdsgram";
import {Tools} from "../../../../Tools";

export type IDailyBonus = {
    collecting_days: number
    can_collect: boolean
}
type IErrors = 1005 | 1006
const ERRORS = [1005, 1006] as IErrors[]

export const Task = () => {
    const { state, open: openTon, close } = useTonConnectModal();
    const [open, setOpen] = useState(getter(SIDEBARS) === 'task');
    const [task, setTask] = React.useState<ITask>({} as ITask)
    const {openSnackbar}= useEnqueueSnackbar()
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const {id, payload} = getter(PAYLOAD)

    const getTaskById = async () => {
        try {
            const {data} = await game.tasks.getById(id)
            setTask(data)
        } catch (e) {

        }
    }

    const {onError, onReward} = useAdsgramCallback('task', getTaskById)


    const onClose = () => {
        setOpen(false);
        setTimeout(() =>
                setter(SIDEBARS, '')
            , 500)
    };

    const classNames = {
        mask: styles.sidebar_mask,
        content: styles.sidebar_content,
        wrapper: styles.sidebar_wrapper,
        body: styles.sidebar_body
    }

    const Hooks = {
        ads: useAdsgram(({ blockId: "2083", onReward, onError }))
    }


    React.useEffect(() => {
        getTaskById()
    },[])

    // if (!task.id) return <></>


    const checkTask = async () => {
        try {
            const {data} = await game.tasks.check(id)

            if (data.success) {
                setTimeout(() => setter(SIDEBARS, ''), 200)
                setter(ANCHOR, Math.random())
                return openSnackbar({message: taskTexts.snackbar.success[langCode], variant: 'success'})
            }
            const error_code =
                ERRORS.indexOf(data.error.error_code) >= 0
                    ? Number(data.error.error_code) as IErrors: 'unknown'
            return openSnackbar({message: taskTexts.snackbar.error[error_code][langCode], variant: 'error'})

        } catch (e) {
            return openSnackbar({message: taskTexts.snackbar.error.unknown[langCode], variant: 'error'})
        }
    }

    const openTask = async () => {
        if (payload.includes('setter')) {
            return setterPayload(payload)
        }
        if (payload.includes('hooks')) {
            return hooksPayload(payload)
        }
        if (payload.includes('open_app')) {
            return openappPayload(payload)
        }
        Telegram.WebApp.openTelegramLink(payload)
    }

    const hooksPayload = (pl: string) => {
        const [_, hook] = pl.split(':')
        switch (hook) {
            case 'ads':
                return Hooks.ads()
        }

    }
    const openappPayload = (pl: string) => {
        const [_, link] = pl.split('|')
        game.tasks.check(id, true)
        return Telegram.WebApp.openTelegramLink(link)

    }

    const setterPayload = (pl: string) => {
        const [_, type, action] = pl.split(':')
        switch (type) {
            case 'page':
                onClose()
                return setter(PAGE, action as Pages)
            case 'ton':
                onClose()
                return openTon()
            case 'sidebar':
                return setter(SIDEBARS, action as ISidebars)


        }
    }


    return (
        <Drawer
            classNames={classNames}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            open={open}
        >
            <div className={'cross'} onClick={onClose}>
                <CloseOutlined/>
            </div>

            <div className={styles.content}>


                <Photo src={`./assets/tasks/${task.icon}`} className={styles.content_img}/>

                <p className={styles.content_award}>+ {task.award} min.</p>

                <p className={styles.content_title}>{task.title}</p>

                <p>{task.description}</p>

                <div className={styles.content_action}>
                    <button className={`btn_primary`} onClick={openTask}>{taskTexts.btn.action[langCode]}</button>
                    <button className={`btn_primary`} onClick={checkTask}>{taskTexts.btn.check[langCode]}</button>
                </div>

            </div>
        </Drawer>
    )
}
