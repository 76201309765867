import {game} from "../../../../Api";
import React, {FC} from "react";
import {AllUsersWay} from "./Way";
import styles from './AllUsersWay.module.scss'
import {ILangProp} from "../../../../../Struct/Screens/Top";

export const TopAllUsersWay: FC<ILangProp> = ({langCode}) => {
    const [loading, setLoading] = React.useState(true)
    const [users, setUsers] = React.useState(0)

    const getAllUsers = async () => {
        try {
            const {data} = await game.users.getAllUsers()
            setUsers(data.all_users)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getAllUsers()
    }, [])

    return (
        <div className={styles.way}>
            <AllUsersWay loading={loading} users={users} langCode={langCode}/>
        </div>
    )
}
