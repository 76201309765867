import styles from './Banned.module.scss'
import clsx from "clsx";
import MovingDotsCanvas from "../../../UI/Animations/MovingDots";
import {Header} from "../../../PreHeader";
import { useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE } from "../../../../state/elum";


export const Banned = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE);



    return (
        <div className={clsx([styles.timeend])} id='time_end'>
            <MovingDotsCanvas
                dotsCount={200}
                className={clsx(styles.timeend_dots)}
            />
            <Header.Ban/>

        </div>
    )
}