import {setter, useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE, PAGE, SIDEBARS, THEME, USER} from "../../../state/elum";
import {Timer} from "./entity/Timer";
import React from "react";
import styles from './Game.module.scss'
import {Button} from "antd";
import {button} from "./entity/texts";
import {game} from "../../../Api";
import CryptoJS from "crypto-js";
import {GameLoader} from "../../UI/Loaders/Pages/Game";
import clsx from "clsx";
import {APP_MODE} from "../../../Api/config";
import { Diamond } from "../../../../Assets/icons";
import {IS_TESTER} from "../../../Api/config.search";

export const TimeBlock = () => {
    const user= useGlobalValue(USER)
    const [loading, setLoading] = React.useState(!user.is_loaded)
    const theme = useGlobalValue(THEME)
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const timeDifferance = (user.time * 1000) - Date.now()

    const getTime = async  () => {
        setLoading(true)
        try {
            const {data} = await game.users.sync(['time'])
            const time = +CryptoJS.AES.decrypt(data.time, String(user.tid)).toString(CryptoJS.enc.Utf8)
            setter(USER,(data) =>  ({...data,time}))

        } catch (e) {

        } finally {
            setLoading(false)
        }
    }



    React.useEffect(() => {
        if (user.is_loaded) {
            getTime()
        }
    },[user.is_loaded])

    if (!user.is_loaded || loading) return <GameLoader/>


    return (
        <div className={styles.game}>
            <Timer timeDiff={timeDifferance} theme={theme} styles={styles} langCode={langCode}/>
            <div className={styles.game_buttons}>
                <Button type='primary' className={styles.game_button} onClick={() => setter(SIDEBARS, 'add_time')}>{button.add_time[langCode]}</Button>
                {IS_TESTER &&
                    <button className={clsx([styles.game_button, 'btn_primary'])} onClick={() => setter(PAGE, 'drop')}>
                        <Diamond />
                    </button>
                }
            </div>
        </div>
    )
}
