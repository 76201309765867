import {FriendsHeader} from "./Header";
import {FriendsBox} from "./Body/Users";
import {FC} from "react";

export type IFriendsContent = {
    scrollToEnd: boolean
}

export const FriendsContent: FC<IFriendsContent> =  ({scrollToEnd}) => {

    return (
        <>
            <FriendsHeader/>
            <FriendsBox scrollToEnd={scrollToEnd}/>
        </>
    )
}
