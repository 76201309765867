import {getter} from "elum-state/react";
import {USER} from "../../../../state/elum";
import React from "react";
import {game} from "../../../../Api";
import styles from  './Header.module.scss'
import {Skeleton} from "../../../UI/Loaders/Skeleton";
import {Photo} from "../../../UI/Avatar";
import {API_URL} from "../../../../Api/config";
import {Tools} from "../../../../Tools";

export const TopHeader = () => {
    const user = getter(USER)
    const [position, setPosition] = React.useState<null | number>(null)
    const minutes = Math.floor(((user.time - (Date.now() / 1000)) / 60))

    const getPosition = async () => {

        try {
            const {data} = await game.users.getMyPosition()
            setPosition(data.position)
        }   catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        getPosition()

    }, [])

    if (position === null) return <div className={styles.header}><Skeleton/></div>

    return (
        <div className={styles.header}>
            <div className={styles.header_info}>
                <Photo src={user.photo} alt={user.username.slice(0, 2)}/>
                <div>
                    <p className={styles.header_info_name}>{user.username ?? `#${user.tid}`}</p>
                    <p className={styles.header_info_balance}>{Tools.Spacer(minutes)}</p>
                </div>
            </div>
            <div>
                <p className={styles.header_info_position}>🏆 {position}</p>
            </div>
        </div>
    )

}
