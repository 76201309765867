export const earnHeader = {
    title: {
        ru: 'Заработай больше времени',
        en: 'Earn more time'
    },
    button: {
        ru: 'Ежедневный бонус',
        en: 'Daily bonus'
    },
    promo: {
        ru: 'Использовать промокод',
        en: 'Enter promocode'
    }
}
