import {Drawer} from "antd";
import React, {useState} from "react";
import {getter, setter, useGlobalValue} from "elum-state/react";
import {BANNED, LANGUAGE_CODE, SIDEBARS, USER} from "../../../../state/elum";
import styles from './Donuts.module.scss'
import {PurpleStar} from "../../../../../Assets/icons";
import {game} from "../../../../Api";
import {Telegram} from "../../../../../index";
import {CloseOutlined} from "@ant-design/icons";
import CryptoJS from "crypto-js";

export type IDailyBonus = {
    collecting_days: number
    can_collect: boolean
}

export const Donuts = () => {
    const [open, setOpen] = useState(getter(SIDEBARS) === 'donuts');
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const user= useGlobalValue(USER)




    const onClose = () => {
        setOpen(false);
        setTimeout(() =>
                setter(SIDEBARS, '')
            , 500)
    };

    const classNames = {
        mask: styles.sidebar_mask,
        content: styles.sidebar_content,
        wrapper: styles.sidebar_wrapper,
        body: styles.sidebar_body
    }


    const buy = async (id: number) => {
        try {
            const {data} = await game.pay.get(id)

            if (data.order_link) {
                Telegram.WebApp.openInvoice(data.order_link, async (a: any) => {
                    if (a === 'paid') {
                        await syncUser()
                    }
                })
            }

        } catch (e) {
            console.log(e)
        }
    }


    const syncUser = async () => {
        setter(USER,(data) =>  ({...data,is_loaded: false}))
        try {
            const {data} = await game.users.sync(['time'])
            const time = +CryptoJS.AES.decrypt(data.time, String(user.tid)).toString(CryptoJS.enc.Utf8)
            setter(USER,(data) =>  ({...data,time}))
            setter(BANNED, false)
        }catch (e) {

        } finally {
            setter(USER,(data) =>  ({...data,is_loaded: true}))
            onClose()
        }
    }



    return (
        <Drawer
            classNames={classNames}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            open={open}
        >

            <div className={'cross'} onClick={onClose}>
                <CloseOutlined/>
            </div>

            <div className={styles.content}>
                {
                    items.map((el, i) =>
                        <div className={styles.content_item} key={`${el.id}_order_row`} onClick={() => buy(el.id)}>
                            <p>
                                {el.value} {el.currency[langCode]}
                            </p>
                            <div className={styles.content_item__cost}>
                                <p>{el.cost}</p>
                                <PurpleStar/>
                                {/*<TgStarGolden/>*/}
                            </div>
                        </div>
                    )
                }

            </div>
        </Drawer>
    )
}



const items = [
    // {
    //     id: 0,
    //     cost: 10,
    //     value: 300,
    //     currency: {
    //         en: 'minutes',
    //         ru: 'минут'
    //     }
    // },
    {
        id: 1,
        cost: 50,
        value: 24,
        currency: {
            en: 'hours',
            ru: 'часов'
        }
    },
    {
        id: 2,
        cost: 250,
        value: 1,
        currency: {
            en: 'month',
            ru: 'месяц'
        }
    },
    {
        id: 3,
        cost: 500,
        value: 4,
        currency: {
            en: 'months',
            ru: 'месяцев'
        }
    },
    {
        id: 4,
        cost: 1000,
        value: 1,
        currency: {
            en: 'year',
            ru: 'год'
        }
    },
    {
        id: 5,
        cost: 5000,
        value: 10,
        currency: {
            en: 'years',
            ru: 'лет'
        }
    },
];

