export const buttons = {
    main: {
        en: 'Add time',
        ru: 'Добавить время'
    }
}

export const all_users_way = {
    en: 'players',
    ru: 'игроков'
}
