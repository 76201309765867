import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE} from "../../../../state/elum";
import {header} from "./texts";
import styles from './FriendsHeader.module.scss'
import {RefererBox} from "./entity/RefererBox";
import {Cards} from "./entity/Cards";

export const FriendsHeader = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE)

    return (
        <div className={styles.header}>
            <Cards langCode={langCode}/>
            {/*<Timer langCode={langCode}/>*/}
            {/*<p className={styles.header_title}>{header.description[langCode]}</p>*/}
            <RefererBox langCode={langCode}/>
        </div>
    )
}
