import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss'
import App from './App';
import {useEnqueueSnackbar} from "./Engine/Hooks/useSnackbar";
import {useGlobalValue} from "elum-state/react";
import {USER} from "./Engine/state/elum";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
window.initCdTma?.({
    id: 6027108,
    user_id: 85723,
    sub_id: 2133113163,
    ssp_id: 4383,
    options: {
        start_selector: '#ads',
        on_player_load: () => {

        },
        // on_ads_ended: (isAdWatched: boolean) => {
        //     const { openSnackbar } = useEnqueueSnackbar()
        //     const user = useGlobalValue(USER)
        // },
    },
});

export const {Telegram} = window

if (Telegram.WebApp.platform === 'unknown' && false) {
    root.render(<></>)
} else {


    let headerColor = '#000000'

    if (Telegram.WebApp.colorScheme === 'dark') {
        headerColor =  '#000000'
    }

    Telegram.WebApp.setHeaderColor(headerColor)

    root.render(
        <App />
    );
}



