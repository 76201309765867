import axios from "axios";
import {encryptedData, version} from "../../../Engine/Api/config.search";
import {APP_MODE} from "../../../Engine/Api/config";



export let API_URL = 'http://127.0.0.1:3002'

switch (APP_MODE) {
    case 'dev':
        API_URL = 'http://127.0.0.1:3002'
        break
    case 'prod':
        API_URL = 'https://api.thetime.pro/casino'
        break

}

const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use((config) => {
    config.headers.Authorization = encryptedData
    config.headers.version = version
    return config
})



export { api}
