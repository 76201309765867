import {api} from "../config";

class GeneralCasinoApiClass {
    public events: Events
    public users: Users
    public tasks: Tasks
    public donuts: Donuts
    public withdraw: Withdraw
    public slots: Slots
    public ads: Ads
    constructor() {
        this.events = new Events();
        this.users = new Users();
        this.tasks = new Tasks();
        this.donuts = new Donuts();
        this.withdraw = new Withdraw();
        this.slots = new Slots();
        this.ads = new Ads();
    }
}

class Events {
    private url: string
    constructor() {
        this.url = 'events'
    }

    get () {
        const hand = `${this.url}.get`
        return api.get(hand)
    }

}

class Users {
    private url: string
    constructor() {
        this.url = 'users'
    }

    get () {
        const hand = `${this.url}.get`
        return api.get(hand)
    }

}
class Tasks {
    private url: string
    constructor() {
        this.url = 'tasks'
    }

    get(type: string) {
        const hand = `${this.url}.get?type=${type}`
        return api.get(hand)
    }
    getById(id: string) {
        const hand = `${this.url}.get/${id}`
        return api.get(hand)
    }
    check(id: string, is_open_app = false) {
        const hand = `${this.url}.check/${id}` + (is_open_app ? `?open_app=${is_open_app}` : '')
        return api.get(hand)
    }

}

class Donuts {
    private url: string
    constructor() {
        this.url = 'pay'
    }
    get(id = 0) {
        const hand = `${this.url}.get` + (!id ? '' : `?id=${id}`)
        return api.get(hand)
    }
}

class Withdraw {
    private url: string
    constructor() {
        this.url = 'withdraw'
    }
    create(data = {}) {
        const hand = `${this.url}.create`
        return api.post(hand, data)
    }
}

class Slots {
    private url: string
    constructor() {
        this.url = 'slots'
    }
    spin() {
        const hand = `${this.url}.spin`
        return api.get(hand)
    }
}


class Ads {
    private url: string
    constructor() {
        this.url = 'ads'
    }

    check() {
        const hand = `${this.url}.check`
        return api.get(hand)
    }

}


const generalCasino = new GeneralCasinoApiClass()

export {generalCasino}
