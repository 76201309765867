import {Divider} from "../../../UI/Divider";
import styles from './Body.module.scss'
import {game} from "../../../../Api";
import React from "react";
import {TasksBody} from "./Tasks";
import {getter, useGlobalValue} from "elum-state/react";
import {ANCHOR, LANGUAGE_CODE} from "../../../../state/elum";
import {body} from "./texts";

export type ITask = {
    "id": number,
    "title": string,
    "icon": string,
    description: string
    payload: string
    award: number
}

export const EarnBody = () => {
    const nullArray = Array.from({length: 10}, () => undefined)
    const [tasks, setTasks] = React.useState<ITask[] | undefined[]>(nullArray);
    const langCode = getter(LANGUAGE_CODE)
    const anchor = useGlobalValue(ANCHOR)


    const getTasks = async () => {
        try {
            const {data} = await game.tasks.get('earn')
            const taskWithId8 = data.find((task: any) => task.id === 8);
            const taskWithId18 = data.find((task: any) => task.id === 18);
            const taskWithId34 = data.find((task: any) => task.id === 34);
            const taskWithId48 = data.find((task: any) => task.id === 48);
            const taskWithId52 = data.find((task: any) => task.id === 52);
            const taskWithId53 = data.find((task: any) => task.id === 53);
            const taskWithId54 = data.find((task: any) => task.id === 54);
            const taskWithId64 = data.find((task: any) => task.id === 64);
            const taskWithId72 = data.find((task: any) => task.id === 72);
            const taskWithId77 = data.find((task: any) => task.id === 77);

            const filteredData = data.filter((task: any) =>
                task.id !== 18 &&
                task.id !== 34 &&
                task.id !== 48 &&
                task.id !== 52 &&
                task.id !== 53 &&
                task.id !== 54 &&
                task.id !== 64 &&
                task.id !== 72 &&
                task.id !== 77 &&
                task.id !== 18
            );

                let reorderedData = [...filteredData];
                if (taskWithId18) {
                    reorderedData = [taskWithId18, ...reorderedData];
                }
                if (taskWithId8) {
                    reorderedData = [taskWithId8, ...reorderedData];
                }
                if (taskWithId34) {
                    reorderedData = [taskWithId34, ...reorderedData];
                }
                if (taskWithId48) {
                    reorderedData = [taskWithId48, ...reorderedData];
                }
                if (taskWithId52) {
                    reorderedData = [taskWithId52, ...reorderedData];
                }
                if (taskWithId53) {
                    reorderedData = [taskWithId53, ...reorderedData];
                }
                if (taskWithId54) {
                    reorderedData = [taskWithId54, ...reorderedData];
                }
                if (taskWithId64) {
                    reorderedData = [taskWithId64, ...reorderedData];
                }
                if (taskWithId72) {
                    reorderedData = [taskWithId72, ...reorderedData];
                }
                if (taskWithId77) {
                    reorderedData = [taskWithId77, ...reorderedData];
                }
                setTasks(reorderedData);
        } catch (e) {
            console.log(e)
        }
    }


    React.useEffect(() => {
        getTasks()
    },[anchor])

    return (
        <div className={styles.body}>
            <Divider>{body.title[langCode]}</Divider>
            <div className={styles.body_tasks}>
                <TasksBody tasks={tasks}/>

            </div>
        </div>
    )
}
