export const taskTexts = {
    btn: {
        check: {
            ru: 'Проверить',
            en: 'Check',
        },
        action: {
            ru: 'Выполнить',
            en: 'Perform',
        }
    },
    snackbar: {
        success: {
            en: 'Success',
            ru: 'Успешно'
        },
        error: {
            1005: {
                en: 'Task is already passed',
                ru: 'Задание уже выполнено'
            },
            1006: {
                en: 'Task is not passed',
                ru: 'Задание не выполенено'
            },
            unknown: {
                en: 'Unknown error',
                ru: 'Неизвестная ошибка'
            }

        }
    }
}
