import {Top} from "./Top";
import {Friends} from "./Friends";
import {Earn} from "./Earn";
import {Airdrop} from "./Airdrop";
import {AddTime} from "./AddTime";
import {TimeEnd} from "./TimeEnd";
import {Banned} from "./Ban";

export const Header = () => {
    return (<></>)
}

Header.Top = Top
Header.Friends = Friends
Header.Earn = Earn
Header.Airdrop = Airdrop
Header.AddTime = AddTime
Header.Timeend = TimeEnd
Header.Ban = Banned