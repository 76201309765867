import {Ban, } from "../../../../Assets/icons";
import clsx from "clsx";
import styles from '../index.module.scss'
import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE} from "../../../state/elum";


const text = {
    en: "Oops, your are blocked!",
    ru: "Упс, ты заблокирован!"
}

export const Banned = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE);
    return (
        <div className={clsx([styles.classic])}>
            <Ban/>
            <p className={styles.header_title}>{text[langCode]}</p>
        </div>
    )
}