import { useEffect, useState } from 'react';

const useScroll = (elementId: string) => {
    const [scrolledToEnd, setScrolledToEnd] = useState(false);

    useEffect(() => {
        const element = document.getElementById(elementId);

        if (!element) return;

        const handleScroll = () => {
            const scrollTop = element.scrollTop;
            const windowHeight = element.clientHeight;
            const documentHeight = element.scrollHeight;
            const scrolledToBottom = scrollTop + windowHeight >= documentHeight - 10;

            setScrolledToEnd(scrolledToBottom);
        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };
    }, [elementId]);

    return scrolledToEnd;
};

export default useScroll;
