import {Skeleton} from "../../../UI/Loaders/Skeleton";
import React, {FC} from "react";
import {ITask} from "./index";
import styles from './Body.module.scss'
import {setter, useGlobalValue} from "elum-state/react";
import {PAYLOAD, SIDEBARS, USER} from "../../../../state/elum";
import DoneAllIcon from '@mui/icons-material/DoneAll';

type ITasksBody = {
    tasks: ITask[] | undefined[]
}

export const TasksBody: FC<ITasksBody> = ({tasks}) => {
    const walletAddress = useGlobalValue(USER).ton_address


    const clickOnTask = (id: number | undefined, payload: string | undefined, state: number | null) => {
        if (!id || state) return 0

        setter(SIDEBARS, 'task')
        setter(PAYLOAD, {id, payload})

    }
    const formatAddress = (address: string) => {
        if (address.length <= 10) {
            return address;
        }
        const firstPart = address.slice(0, 5);
        const lastPart = address.slice(-5);
        return `${firstPart}...${lastPart}`;
    };

    return (
        <>
            {tasks.map((el, i) =>
                <div className={styles.body_tasks__row} onClick={() => clickOnTask(el?.id, el?.payload, el!.state)} key={`${i}_task`}>
                    {
                        el ?
                            <>
                                <div className={styles['body_tasks__row-box']}>
                                    <img src={`./assets/tasks/${el.icon}`} className={styles['body_tasks__row-img']}/>
                                    <div className={styles['body_tasks__row-info']}>
                                        <p className={styles['body_tasks__row-title']}>{el.title}</p>
                                        {
                                            !el.state ?
                                                <p className={styles['body_tasks__row-award']}>+ {el.award} min.</p> :
                                                <p className={styles['body_tasks__row-award']}>
                                                    {formatAddress(walletAddress)}
                                                </p>

                                        }
                                    </div>
                                </div>
                                {
                                    el.state && <DoneAllIcon className={styles['body_tasks__row-done']}/>
                                }
                            </>
                            : <></>
                    }
                </div>
            )}
        </>
    )
}
