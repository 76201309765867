import {FC} from "react";
import {IProps} from "../../../types/props";
import styles from './Divider.module.scss'

interface IDivider extends IProps{
    orientation?: string
    className?: string
}

export const Divider: FC<IDivider> = ({orientation = 'left', children, className}) => {


    return (
        <div className={`${styles.divider} ${className ?? ''}`}>
            <span className={styles.divider_span}>
                {children}
            </span>
        </div>
    )

}
