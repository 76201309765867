import CryptoJS from 'crypto-js';
import {encryptedData} from "../Api/config.search";
import {Telegram} from "../../index";
export class Tools {
    static formatTimeDifference(durationInMs: number) {
        //const milliseconds = Math.floor((durationInMs % 1000) / 10); // Преобразуем миллисекунды в сотни
        const seconds = Math.floor((durationInMs / 1000) % 60);
        const minutes = Math.floor((durationInMs / (1000 * 60)) % 60);
        const hours = Math.floor((durationInMs / (1000 * 60 * 60)) % 24);
        const days = Math.floor((durationInMs / (1000 * 60 * 60 * 24)) % 365);
        const years = Math.floor(durationInMs / (1000 * 60 * 60 * 24 * 365));

        const timeParts: {count: number, title: { en: string; ru: string }, className: string}[] = [];

        const addTimePart = (count: number, title: { en: string; ru: string }) => {
            let className: string = 'default';
            if (count === 0 && !timeParts.some(part => part.count > 0)) {
                className = 'red';
            }
            timeParts.push({ count, title, className });
        };

        addTimePart(years, { en: 'y.', ru: 'г.' });
        addTimePart(days, { en: 'd.', ru: 'д.' });
        addTimePart(hours, { en: 'h.', ru: 'ч.' });
        addTimePart(minutes, { en: 'm.', ru: 'м.' });
        addTimePart(seconds, { en: 's.', ru: 'с.' });
        //addTimePart(milliseconds, { en: 'ms', ru: 'мс' });

        return timeParts;
    }


    static getContrastColor(hexColor: string) {
        hexColor = hexColor.replace('#', '');

        const r = parseInt(hexColor.substring(0, 2), 16);
        const g = parseInt(hexColor.substring(2, 4), 16);
        const b = parseInt(hexColor.substring(4, 6), 16);

        const brightness = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

        return brightness > 130 ? 'black' : 'white';
    }

    static getRandomColor() {

        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);

        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    }


    static Spacer(x: number) {
        if (x <= 0) return  '0'
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    static NumberFormatter(num: number): string {
        const units = ['К', 'М', 'В','Т', 'QD', 'QT', 'ST', 'SP','O', 'N', 'D', 'U', 'DUO'];
        const base = Math.floor(Math.log10(Math.abs(num)) / 3);

        if (base <= 0) {
            return num.toFixed(0).toString();
        }

        const unitIndex = Math.min(base - 1, units.length - 1);
        const formattedNum = num / Math.pow(10, base * 3);

        return formattedNum.toFixed(2) + units[unitIndex];
    }

    static getHash(param: string) {

        const timestamp = Date.now();

        const randomPart = Math.floor(Math.random() * 0xFFFFFFFF);
        const salt = (timestamp ^ randomPart).toString(16);


        const dataToEncrypt = `${salt}:${param}:${timestamp}`;

        const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, encryptedData).toString();

        return {
            hash: encrypted,
        };

    }

    static openProfile = (username: string | null | undefined = null) => (() =>  {
        if (!username) return 0;
        Telegram.WebApp.openTelegramLink(`https://t.me/${username}`)
    })

    static getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
