import styles from './Airdrop.module.scss'
import {AirdropHead} from "../../../Engine/Components/Screens/Airdrop/Head";
import {AirdropBody} from "../../../Engine/Components/Screens/Airdrop/Body";
import clsx from "clsx";
import {Header} from "../../../Engine/Components/PreHeader";

export const Airdrop = () => {
    const height = document.getElementById('menu')!.clientHeight

    return (
        <div className={clsx([styles.earn, 'fade-in'])} style={{paddingBottom: `${height + 10}px`}}>
            <Header.Airdrop/>
            <AirdropHead/>
            <AirdropBody/>
        </div>
    )
}
