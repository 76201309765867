import {Layout} from "antd";
import React from "react";
import {game} from "../../Engine/Api";
import {getter, setter} from "elum-state/react";
import {BANNED, THEME} from "../../Engine/state/elum";
import styles from './Main.module.scss'
import {CircularBox} from "../../Engine/Components/UI/Loaders/CircularBox";
import {RegistrationPage} from "../RigistrationPage/RegistrationPage";
import {MainWrapper} from "../MainWrapper/MainWrapper";
import {GameLoader} from "../../Engine/Components/UI/Loaders/Pages/Game";

export const Main = () => {
    const [userExist, setUserExist] = React.useState<number | null>(null)
    const theme = getter(THEME)

    const getUserExist = async () => {
        try {
            const {data} = await game.users.auth()
            setUserExist(data.user_exist)
            if (data?.error?.code === 7) {
                setter(BANNED, 'banned')
            }
            console.log(data)
            if (!data.user_exist) {
                await game.users.reg()
                setUserExist(1)
            }

        } catch (e) {
            console.log(e)
        }

    }



    React.useEffect(() => {
        getUserExist()
    }, [])
    return (
        <div className={`${styles[theme]} ${styles.main} `}>
            {userExist === null && <GameLoader/>}
            {/*{userExist === 0 && <RegistrationPage setUserExist={setUserExist}/>}*/}
            {userExist === 1 && <MainWrapper/>}
        </div>
    )
}
