import CryptoJS from 'crypto-js';
const searchParams = new URLSearchParams(window.Telegram.WebApp.initData);
const tgUser = JSON.parse(String(searchParams.get('user')))
const langCode = (tgUser?.language_code ?? 'ru') as 'ru' | 'en'
const theme = window.Telegram.WebApp.colorScheme as 'dark' | 'light'

const admins = [756656853,5179810678,476435673, 5410669297]
export const IS_TESTER = true
//admins.includes(tgUser.id)

const paramsObject: Record<string, string> = {};
searchParams.forEach((value, key) => {
    paramsObject[key] = value;
});

const checkString = Object.keys(paramsObject)
    .sort()
    .map(key => `${key}=${paramsObject[key]}`)
    .join("\n");

// console.log(paramsObject)

const base64String = btoa(encodeURIComponent(checkString));

const timestamp = Date.now();

const randomPart = Math.floor(Math.random() * 0xFFFFFFFF);
const salt = (timestamp ^ randomPart).toString(16);

const encryptedData = CryptoJS.AES.encrypt(base64String, salt).toString() + salt
const version = btoa(CryptoJS.AES.encrypt(base64String, salt).toString().length.toString(4))

export { searchParams, checkString, encryptedData, version, tgUser, langCode, theme };
