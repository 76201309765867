export const daily = {
    box: {
        title: {
            en: 'Day',
            ru: 'День'
        },
        desc: {
            en: 'h.',
            ru: 'ч.'
        }
    },
    button: {
        action: {
            en: 'Collect',
            ru: 'Собрать'
        },
        not_action: {
            en: 'Come back tomorrow',
            ru: 'Приходите завтра'
        }
    }
}
