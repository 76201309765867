import {FC} from "react";
import {FieldTimeOutlined} from "@ant-design/icons";
import styles from '../DailyBonus.module.scss'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {ILangProp} from "../../../../../../Struct/Screens/Top";
import {daily} from "../data/texts";


type IDayBox = {
    langCode: 'ru' | 'en'
    active: boolean
    award: number
    collected: boolean
    day: number
}

export const DayBox: FC<IDayBox> = ({collected, active,award,day, langCode}) => {

    return (
        <div className={`${styles.content_awards__item} ${active && styles['content_awards__item-active']}`}>
            {collected &&
                <div className={styles['content_awards__item-collected']}>
                    <DoneAllIcon/>
                </div>
            }
            <p>{daily.box.title[langCode]} {day}</p>
            <FieldTimeOutlined className={styles.content_awards__logo} />
            <p>{award} {daily.box.desc[langCode]}</p>
        </div>
    )
}
