import React, {FC, useEffect, useState} from "react";
import { Tools } from "../../../../Tools";
import {setter} from "elum-state/react";
import {USER} from "../../../../state/elum";

type ITimer = {
    timeDiff: number;
    theme: string
    styles: any
    langCode: 'ru' | 'en'
};

const MS = 1000

export const Timer: FC<ITimer> = ({ timeDiff, theme,styles,langCode }) => {
    const [remainingTime, setRemainingTime] = useState(timeDiff <= 0 ? 0 : timeDiff);
    const [isEnd, setIsEnd] = useState(false);
    let timeParts = Tools.formatTimeDifference(remainingTime)



    const setTime = () => {
        setter(USER, state => ({...state, time: state.time - 1}))
        setRemainingTime(prevTime => {
            if (prevTime <= MS) {
                setIsEnd(true);
                return 0;
            }
            return prevTime - MS;
        });
    };

    useEffect(() => {
        let interval: NodeJS.Timer;
        if (!isEnd) {
            interval = setInterval(setTime, MS);
        }
        return () => clearInterval(interval);
    }, [isEnd, timeDiff]);


    return (
        <div className={`${styles[theme]} ${styles.timer}`}>
            {
                timeParts.map((el, i) =>
                    <div key={`${i}_time_block`}>
                        <div>
                            <p
                                className={`${styles.timer_first} ${el.className === 'default' ? styles.timer_first_default : styles.timer_first_red}`}
                            >
                                {String(el.count).padStart(2,'0')}
                            </p>
                        </div>
                        <div>
                            <p className={styles.timer_second}>{el.title[langCode]}</p>
                        </div>
                    </div>
                )
            }
        </div>
    );
};
