import {Game} from "./Game";
import {useGlobalValue} from "elum-state/react";
import {PAGE, Pages} from "../../Engine/state/elum";
import {Top} from "./Top";
import {Friends} from "./Friends";
import {Earn} from "./Earn";
import {Airdrop} from "./Airdrop";



const Screen = {
    game: <Game/>,
    top: <Top/>,
    friends: <Friends/>,
    earn: <Earn/>,
    drop: <Airdrop/>
}

export const Screens = () => {
    const page = useGlobalValue(PAGE)

    return (<>{Screen[page as Pages]}</>)
}
