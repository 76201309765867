import {setter, useGlobalValue} from "elum-state/react";
import {PAGE, Pages} from "../../../state/elum";
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {Menu} from "./Constructor/Menu";
import {ReactElement} from "react";
import styles from './MenuPanel.module.scss'
import {Diamond, Dice} from "../../../../Assets/icons";
import {useNavigate} from "react-router-dom";
import {IS_TESTER} from "../../../Api/config.search";

export const MenuPanel = () => {
    const current = useGlobalValue(PAGE)
    const navigate = useNavigate()

    const onClick = (e: Pages, type: string | undefined) => {
        if (type === 'router') {
            return navigate(e)
        }

        setter(PAGE, e)

    };

    return (
        <div className={styles.menuPanel} id='menu'>
            <Menu onClick={onClick} selectedKey={current}  items={items} />
        </div>
    );
}


export type IMenuItem = {
    menuKey: string,
    icon: ReactElement<any, any>,
    type?: string,
    icon_type?: string,
    title?: {
        en: string,
        ru: string
    }
};

const items: IMenuItem[] = [

    {
        menuKey: 'top',
        title: {
            en: 'Rating',
            ru: 'Топ'
        },
        icon: <BarChartIcon />,
    },
    {
        menuKey: 'game',
        title: {
            en: 'Time',
            ru: 'Время'
        },
        icon: <AccessTimeOutlinedIcon />,
    },
    {
        menuKey: 'casino',
        type: 'router',
        title: {
            en: 'Games',
            ru: 'Игры'
        },
        icon: <Dice />,
        icon_type: 'animated'
    },
    {
        menuKey: 'friends',
        title: {
            en: 'Friends',
            ru: 'Друзья'
        },
        icon: <GroupIcon />,
    },
    {
        menuKey: 'earn',
        title: {
            en: 'Earn',
            ru: 'Задания'
        },
        icon: <DoneAllIcon />,
    },
    {
        menuKey: 'drop',
        title: {
            en: 'Drop',
            ru: 'Дроп'
        },
        icon: <Diamond />,
    },

]
    .filter(el => {
        return !(!IS_TESTER && el.menuKey === 'casino');

    });
