import styles from './TimeEnd.module.scss'
import clsx from "clsx";
import MovingDotsCanvas from "../../../UI/Animations/MovingDots";
import {Header} from "../../../PreHeader";
import {setter, useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE, SIDEBARS} from "../../../../state/elum";

const text = {
    en: 'Buy time',
    ru: 'Купить время',
}

export const TimeEnd = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE);

    const open = () => {
        setter(SIDEBARS, 'donuts')
    }

    return (
        <div className={clsx([styles.timeend])} id='time_end'>
            <MovingDotsCanvas
                dotsCount={200}
                className={clsx(styles.timeend_dots)}
            />
            <Header.Timeend/>
            <button className={clsx(['btn_primary', styles.timeend_btn])} onClick={open}>{text[langCode]}</button>

        </div>
    )
}