import {FC} from "react";
import {header} from "../texts";
import styles from '../FriendsHeader.module.scss'
import clsx from "clsx";

type ICards = {
    langCode: 'en'|'ru';
}

export const Cards: FC<ICards> = ({langCode}) => {
    return (
        <div className={clsx([styles.cards])}>
            <div className={clsx([styles.cards_item_1, styles.cards_item])}>
                <p>{header.cards.first[langCode]}</p>
            </div>

            <div className={clsx([styles.cards_item_2, styles.cards_item])}>
                <p>{header.cards.second[langCode]}</p>
            </div>

        </div>
    )
}