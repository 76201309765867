import React from 'react';
import {useSnackbar} from "notistack";
import {CloseOutlined} from "@ant-design/icons";


export const useEnqueueSnackbar = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const openSnackbar = ({message, variant = 'default', options = {}}: {options?: any,message: string, variant?: "default" | "error" | "success" | "warning" | "info" | undefined}) => {
        enqueueSnackbar(message, {...options, variant });
    };

    return {openSnackbar, closeSnackbar};
};

export const SnackbarCloseButton = ({snackbarKey}: any) => {
    const {closeSnackbar} = useEnqueueSnackbar();

    return (
        <CloseOutlined onClick={() => closeSnackbar(snackbarKey)}/>
    );
};
