import React, {FC} from "react";
import {game} from "../../../../../Api";
import {FriendsRow} from "./Friends.row";

import styles from '../../../UsersList.module.scss'
import {getter} from "elum-state/react";
import {LANGUAGE_CODE, USER} from "../../../../../state/elum";
import {FriendsInfo} from "../Info/FriendsInfo";
import {IFriendsContent} from "../../index";

export type IFriends = {
    tid: number,
    time: number,
    username: string,
    last_name: string,
    first_name: string
    award: number
}

export const FriendsBox: FC<IFriendsContent> = ({scrollToEnd}) => {
    const height = document.getElementById('menu')!.clientHeight
    const nullArray = Array.from({length: 10}, () => undefined)
    const langCode = getter(LANGUAGE_CODE)

    const [friends, setFriends] = React.useState<IFriends[] | undefined[]>(nullArray)
    const [loading, setLoading] = React.useState(true)
    const [offset, setOffset] = React.useState(0)
    const [allFreinds, setAllFriends] = React.useState<IFriends[] | undefined[]>([])
    const [totalCount, setTotalCount] = React.useState(0)
    const getFriends = async () =>{
        try {

            const {data} = await game.friends.get()
            setAllFriends(data.users)
            setFriends(data.users.slice(0, 25))
            setTotalCount(data.total_count)
        } catch (e) {

        } finally {
           setLoading(false)
        }
    }


    const next = () => {
        const newFriends = allFreinds.slice(offset + 25, offset + 50)
        const newAllFriends = [...friends, ...newFriends] as IFriends[]
        setFriends(newAllFriends)
        setOffset(prev => prev + 25)

    }


    const UsersResult = React.useMemo(() => {
        return friends.map((el, i) =>
            <div key={`${i}_friends_row`} className={styles.body_row}>
                <FriendsRow user={el} loading={loading} langCode={langCode}/>
            </div>
        )
    }, [friends])

    React.useEffect(() => {
        getFriends()
    },[])
    React.useEffect(() => {
        if (offset < 475 && scrollToEnd && !loading) {
            next()
        }
    }, [scrollToEnd])

    return (
        <div className={styles.body} style={{paddingBottom: `${height + 10}px`}}>
            <FriendsInfo loading={loading} count={totalCount}/>
            {UsersResult}
        </div>
    )
}
