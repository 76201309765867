import React, {FC} from "react";
import {IDailyBonus} from "../index";
import days from '../data/days.json'
import {DayBox} from "./DayBox";
import styles from '../DailyBonus.module.scss'
import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE} from "../../../../../state/elum";
import {daily} from "../data/texts";


interface IDailyContent extends IDailyBonus {
    langCode: 'ru' | 'en'
}
export const DailyBonusContent: FC<IDailyContent> = ({collecting_days, can_collect,langCode}) => {
    const Days = React.useMemo(() =>
            <div className={styles.content_awards}>
                {
                    days.map(el =>
                        <DayBox
                            langCode={langCode}
                            key={`${el.id}_day_box`}
                            day={el.id}
                            collected={collecting_days >= el.id}
                            award={el.award}
                            active={can_collect && collecting_days + 1 === el.id}
                        />
                    )
                }
            </div>
        , [collecting_days, can_collect])



    return (
        <div className={styles.content}>
            {Days}
        </div>
    )

}
