import React from 'react';
import {Main} from "./Struct/Main/Main";
import {ConfigProvider} from "antd";
import {theming} from "./styles/theme";
import {getter} from "elum-state/react";
import {THEME} from "./Engine/state/elum";
import {TonConnectUIProvider} from "@tonconnect/ui-react";

function App() {
    const theme = getter(THEME)

    return (
        <ConfigProvider theme={theming[theme]}>
            <TonConnectUIProvider manifestUrl="https://thetime.pro/tonconnect-manifest.json">
                <Main/>
            </TonConnectUIProvider>
        </ConfigProvider>
    )
}

export default App;
