import {FC} from "react";
import {Skeleton} from "../../../UI/Loaders/Skeleton";
import {Tools} from "../../../../Tools";
import {ILangProp} from "../../../../../Struct/Screens/Top";
import {all_users_way} from "../texts";
import {Divider} from "../../../UI/Divider";

interface IAllUsersWay extends ILangProp {
    loading: boolean,
    users: number,
}

export const AllUsersWay: FC<IAllUsersWay> = ({loading, users, langCode}) => {


    if (loading) return <Skeleton/>
    return (
        <div>
            <Divider>{Tools.NumberFormatter(users)} {all_users_way[langCode]}</Divider>
        </div>
    )
}
