import {Button, Drawer} from "antd";
import React, {useCallback, useState} from "react";
import {getter, setter, useGlobalValue} from "elum-state/react";
import { LANGUAGE_CODE, PAGE, SIDEBARS} from "../../../../state/elum";
import styles from './AddTime.module.scss'
import {addTimeTexts} from "./texts";
import {CloseOutlined} from "@ant-design/icons";
import {useAdsgram, useAdsgramCallback} from "../../../../Hooks/useAdsgram";
import {Header} from "../../../PreHeader";
import clsx from "clsx";
import {TgStar} from "../../../../../Assets/icons";


export const AddTime = () => {
    const [open, setOpen] = useState(getter(SIDEBARS) === 'add_time');
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const {onError, onReward} = useAdsgramCallback('ads')


    const onClose = async () => {
        setOpen(false);
        setTimeout(() =>
                setter(SIDEBARS, '')
            , 500)
    };

    const classNames = {
        mask: styles.sidebar_mask,
        content: styles.sidebar_content,
        wrapper: styles.sidebar_wrapper,
        body: styles.sidebar_body
    }


    const openSidebar = async (type: string) => {
        switch (type) {
            case 'donuts':
                setter(SIDEBARS, '')
                setOpen(false);
                setter(SIDEBARS, 'donuts')
                break
            case 'tasks':
                setter(SIDEBARS, '')
                setOpen(false);
                setter(PAGE, 'earn')
                break
            case 'friends':
                setter(SIDEBARS, '')
                setOpen(false);
                setter(PAGE, 'friends')
                break
        }

    }



    const showAd =
    //     () => {
    //     const el = document.getElementById('ads')!
    //     el.style.display = 'block'
    //
    // }
        useAdsgram({ blockId: "2083", onReward, onError });

    return (
        <Drawer
            classNames={classNames}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            open={open}
        >
            <Header.AddTime/>

            <div className={'cross'} onClick={onClose}>
                <CloseOutlined/>
            </div>

            <div className={styles.content}>
                <Button type='primary' className={styles.button}
                        onClick={() => openSidebar('tasks')}>{addTimeTexts.tasks[langCode]}</Button>
                <button className={clsx([styles.button, 'golden'])}
                        onClick={() => openSidebar('donuts')}>
                    {addTimeTexts.donut[langCode]}
                    <TgStar/>
                </button>
                <Button type='primary' className={styles.button}
                        onClick={() => openSidebar('friends')}>{addTimeTexts.friends[langCode]}</Button>
                <Button type='primary' className={styles.button}
                        onClick={showAd}>{addTimeTexts.ads[langCode]}</Button>

            </div>
            <div id='ads' style={{display: 'none', height: 0, width: 0}}></div>
        </Drawer>
    )
}

