import {FC} from "react";
import {MenuPanel} from "./MenuPanel";
import {IProps} from "../../../types/props";

export const MenuWrapper: FC<IProps> = ({children}) => {
    return (
        <>
            {children}
            <MenuPanel/>
        </>
    )
}
