import {Skeleton} from "../Skeleton";
import styles from './index.module.scss'
import clsx from "clsx";
import {useGlobalValue} from "elum-state/react";
import {BANNED} from "../../../../state/elum";


export const GameLoader = () => {
    const banned = useGlobalValue(BANNED)

    if (banned ==='banned') return <></>


    return (
        <div className={clsx([styles.game])}>
            <div className={clsx([styles.game_time])}>
                <div><Skeleton/></div>
                <div><Skeleton/></div>
                <div><Skeleton/></div>
                <div><Skeleton/></div>
                <div><Skeleton/></div>
            </div>

            <div className={clsx([styles.game_btn])}><Skeleton/></div>

        </div>
    )
}