import React from 'react';
import Lottie from 'lottie-react';
import animationData from './plane.json';
import styles from  './Plane.module.scss'

const LottieAnimation = () => {
    return (
        <div style={{ width: '120px', height: '120px' }} className={styles.plane}>
            <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
};

export default LottieAnimation;