import React from "react";
import {IProps} from "../../../types/props";
import {useGlobalValue} from "elum-state/react";
import {BANNED} from "../../../state/elum";
import {TimeEnd} from "./TimeEnd";
import {Banned} from "./Banned";


const BANNDEDS = {
    time_end: <TimeEnd/>,
    banned: <Banned/>
}

export const BannedWrapper: React.FC<IProps> = ({children}) => {
    const banned = useGlobalValue(BANNED);


    return (
        <>
            {
                banned && BANNDEDS[banned]
            }
            {
                children && children
            }

        </>
    )
}