export const button = {
    add_time: {
        en: 'Add time',
        ru: 'Добавить время'
    },
    airdrop: {
        en: 'Airdrop',
        ru: 'Airdrop'
    }
}

