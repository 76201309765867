import React, {FC} from "react";
import {game} from "../../../../Api";
import styles from '../../UsersList.module.scss'
import {TopBodyRow} from "./TopBody.row";
import useScroll from "../../../../Hooks/useScroll";

export type ITopUser = {
    tid: number,
    time: number,
    username: string,
    last_name: string,
    first_name: string
}

type ITopBody = {
    scrollToEnd: boolean
}

export const TopBody: FC<ITopBody> = ({scrollToEnd}) => {
    const nullArray = Array.from({length: 10}, () => undefined)
    const [users, setUsers] = React.useState<ITopUser[] | undefined[]>(nullArray)
    const [loading, setLoading] = React.useState(true)
    const [offset, setOffset] = React.useState(0)
    const [allUsers, setAllUsers] = React.useState<ITopUser[] | undefined[]>([])

    const getTop = async () => {
        try {
            const {data} = await game.users.getTop()
            setAllUsers(data)
            setUsers(data.slice(0, 25) as ITopUser[])
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const next = () => {
        const newUsers = allUsers.slice(offset + 25, offset + 50)
        const newTopUsers = [...users, ...newUsers] as ITopUser[]
        setUsers(newTopUsers)
        setOffset(prev => prev + 25)

    }

    const UsersResult = React.useMemo(() => {
       return users.map((el, i) =>
        <div key={`${i}_user_top_row`} className={styles.body_row}>
            <TopBodyRow user={el} i={i} loading={loading}/>
        </div>
       )
    }, [users])


    React.useEffect(() => {
        getTop()
    }, [])


    React.useEffect(() => {
        if (offset < 475 && scrollToEnd && !loading) {
            next()
        }
    }, [scrollToEnd])



    return (
        <div className={styles.body}>
            {UsersResult}
        </div>
    )
}
