import {DailyBonus} from "./DailyBonus";
import {useGlobalValue} from "elum-state/react";
import {SIDEBARS} from "../../../state/elum";
import {Task} from "./Task";
import {Donuts} from "./Donuts";
import {AddTime} from "./AddTime";

const sidebars = {
    daily_bonus: <DailyBonus/>,
    task: <Task/>,
    donuts: <Donuts/>,
    add_time: <AddTime/>,
    '': <></>
}

export const Sidebars = () => {
    const sidebar = useGlobalValue(SIDEBARS)

    return (
        <>
            {sidebars[sidebar]}
        </>

    )
}
