import styles from './Head.module.scss'
import {earnHeader} from "./texts";
import {setter, useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE, MODALS, SIDEBARS} from "../../../../state/elum";
import {Logo} from "../../../../../Assets/icons";

export const EarnHead = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE)

    const openDaily = () => setter(SIDEBARS, 'daily_bonus')
    const openPromo = () => setter(MODALS, 'promocodes')

    return (
        <div className={styles.header}>
            {/*<Logo style={{width: '100px'}}/>*/}
            <button className={`btn_primary`} onClick={openDaily}>{earnHeader.button[langCode]}</button>
            <button className={`btn_primary`} onClick={openPromo}>{earnHeader.promo[langCode]}</button>

        </div>
    )
}
