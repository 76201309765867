import TrophyAnimation from "../../../../Assets/icons/trophy";
import clsx from "clsx";
import styles from '../index.module.scss'

export const Top = () => {
    return (
        <div className={clsx([styles.classic])}>
            <TrophyAnimation/>
            <p>Leaderboard</p>
        </div>
    )
}