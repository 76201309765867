export const addTimeTexts = {
    donut: {
        en: 'Stars',
        ru: 'Звезды'
    },
    friends: {
        en: 'Friends',
        ru: 'Друзья'
    },
    tasks: {
        en: 'Tasks',
        ru: 'Задания'
    },
    ads: {
        en: 'Ads',
        ru: 'Реклама'
    }
}
