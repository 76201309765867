
import clsx from "clsx";
import styles from '../index.module.scss'
import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE} from "../../../state/elum";
import {DiamondAnimated} from "../../../../Assets/icons";
import {dropHeader} from "../../Screens/Airdrop/Head/texts";

export const Airdrop = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE);
    return (
        <div className={clsx([styles.classic])}>
            <DiamondAnimated/>
            <p>{dropHeader.title[langCode]}</p>


        </div>
    )
}