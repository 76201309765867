import React, {FC} from "react";
import {Skeleton} from "../../../../UI/Loaders/Skeleton";
import {Photo} from "../../../../UI/Avatar";
import {API_URL} from "../../../../../Api/config";
import {Tools} from "../../../../../Tools";
import {IFriends} from "./index";
import styles from '../../../UsersList.module.scss'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {rowTexts} from "./texts";
import {LinkOutlined} from "@ant-design/icons";

type IRowBody = {
    loading: boolean,
    user: IFriends | undefined,
    langCode: 'ru' | 'en'
}

export const FriendsRow: FC<IRowBody> = ({loading,user, langCode}) => {


    if (!user || loading) return <Skeleton/>
    const username = (() => {
        if (user.username) {
            return user.username
        }
        if (user.first_name) {
            return user.first_name
        }
        return `id${user.tid.toString()}`
    })()

    return (
        <div className={styles.row} onClick={Tools.openProfile(user.username)}>
            <div className={styles.row_info}>
                <Photo src={`${API_URL}/users.photo/${user.tid}`} alt={username.slice(0,2)}/>

                <div className={styles.row_info_box}>
                    <p className={styles.row_info_name}>
                        {user.username ?? `${username}`}
                        {user.username ? <LinkOutlined className={styles.row_info_link} /> : ''}
                    </p>
                    <p className={styles.row_info_balance}>
                        {Tools.Spacer(user.time)} • {rowTexts.credited[langCode]} {user.award} {rowTexts.hours[langCode]}
                    </p>
                </div>

            </div>

            <div className={styles.row_info_done}>
                <HowToRegIcon/>

            </div>

        </div>
    )
}
