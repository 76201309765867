import React, {FC, ReactElement} from "react";
import styles from './Menu.module.scss'
import clsx from "clsx";

type IMenuItem = {
    icon: ReactElement<any, any>,
    title?: {
        en: string,
        ru: string
    }
    langCode: 'ru' | 'en'
}



export const MenuItem: FC<IMenuItem> = ({icon, title,langCode}) => {
    return (
        <div >
            <div className={clsx([styles.menu_box])}>
                {
                    icon
                }
                {
                    title ?
                        <p className={styles.menu_box__paragrah}>{title[langCode]}</p> : <></>
                }
            </div>
        </div>
    )
}
