import React, {FC} from "react";
import {IProps} from "../../../../types/props";
import styles from './Menu.module.scss'
import {Skeleton} from "../../Loaders/Skeleton";
import clsx from "clsx";

interface IMenuItemWrapper extends IProps {
    onClick: (...a: any) => any
    selectedKey: string
    menuKey: string
    theme: string
    animated?: boolean
    type?: string
}

export const MenuItemWrapper: FC<IMenuItemWrapper> = ({onClick, children,menuKey, selectedKey,theme, animated, type}) => {


    return (
        <div
            onClick={() => onClick(menuKey, type)}
            className={clsx(
                styles[theme],
                styles.menu_item_wrapper,
                {[styles.menu_item_wrapper_active]: menuKey === selectedKey},
                {[styles.menu_item_wrapper_animated]: animated}
            )
            }>
            {children}
        </div>
    )
}
